<template lang='pug'>
	.d-flex.create(:class="{'revers': step != 0}")
		.create-bot__w-100.d-flex.justify-content-end
			.d-none.d-lg-flex.align-items-center.create__top-right-buttons
				locale-switch-simple
				span.create-bot__closing(:style="{backgroundColor: isDarkColor ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)'}" @click='openModalClose')
					svg(xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none")
						path(d="M4.36542 4.36572L12.1036 12.1039" :stroke="isDarkColor ? '#eaeaea' : '#082846'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
						path(d="M4.36542 12.104L12.1036 4.36579" :stroke="isDarkColor ? '#fff' : '#082846'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")

		.d-flex.flex-column.position-relative.create__left
			create-left-basic(v-if="step == 0")

			template(v-if="step != 0")
				img.create__paywall.create__paywall--small.in-zoom(src="/assets/img/current/paywall.svg" alt="Paywall")
				span.create__your-project.in-zoom {{ $t('wizard.yourProject') }}
				img.create__decor-image.in-zoom(v-if="step == 1 || step == 2" src="/assets/img/сreate/lightning.png" alt="Lightning decor" :key="key")
				img.create__decor-image.create__decor-image--2.in-zoom(v-if="step == 3" src="/assets/img/сreate/copywriting.png" alt="Copywriting decor" :key="key")
				img.create__decor-image.create__decor-image--3.in-zoom(v-if="step == 4" src="/assets/img/сreate/painting.png" alt="Painting decor" :key="key")
				svg.create__dashed-arrow.in-zoom(xmlns="http://www.w3.org/2000/svg" width="79" height="61" viewBox="0 0 79 61" fill="none")
					path(d="M74.2641 59.8098C74.03 60.31 73.4347 60.5257 72.9345 60.2915L64.7833 56.4762C64.2831 56.242 64.0674 55.6467 64.3015 55.1465C64.5356 54.6463 65.1309 54.4306 65.6311 54.6648L72.8767 58.0562L76.2681 50.8106C76.5022 50.3104 77.0975 50.0947 77.5977 50.3289C78.0979 50.563 78.3136 51.1583 78.0795 51.6585L74.2641 59.8098ZM1.02279 0.342545C2.53967 0.377116 4.21096 0.500259 6.00872 0.724636L5.76103 2.70924C4.0242 2.49247 2.42059 2.37492 0.977215 2.34203L1.02279 0.342545ZM15.7727 2.70708C18.7669 3.53494 21.9261 4.60374 25.1756 5.95065L24.4097 7.79822C21.2378 6.48345 18.1564 5.4412 15.2397 4.63475L15.7727 2.70708ZM34.1085 10.2873C36.8968 11.8494 39.7014 13.6274 42.4789 15.6429L41.3043 17.2616C38.5922 15.2936 35.8536 13.5574 33.131 12.0322L34.1085 10.2873ZM50.1646 21.938C52.5271 24.1156 54.8371 26.4919 57.0651 29.0812L55.5491 30.3857C53.3724 27.856 51.1161 25.5351 48.8091 23.4086L50.1646 21.938ZM63.1112 36.9666C64.9169 39.6146 66.637 42.4383 68.2514 45.4475L66.4891 46.393C64.9081 43.4462 63.2247 40.683 61.4588 38.0934L63.1112 36.9666ZM72.5013 54.4183C73.1259 55.9216 73.7256 57.4636 74.2986 59.0452L72.4183 59.7265C71.8556 58.1736 71.267 56.6603 70.6544 55.1856L72.5013 54.4183Z" fill="white" fill-opacity="0.5")
				.create__decor-wrap
					span.create__your-project {{ $t('wizard.yourProject') }}
					img.create__decor-image(v-if="step == 1 || step == 2" src="/assets/img/сreate/lightning.png" alt="Lightning decor")
					img.create__decor-image.create__decor-image--2(v-if="step == 3" src="/assets/img/сreate/copywriting.png" alt="Copywriting decor")
					img.create__decor-image.create__decor-image--3(v-if="step == 4" src="/assets/img/сreate/painting.png" alt="Painting decor")
					svg.create__dashed-arrow(xmlns="http://www.w3.org/2000/svg" width="66" height="38" viewBox="0 0 66 38" fill="none")
						path(d="M63.3516 36.4582C63.2038 36.9903 62.6526 37.3019 62.1205 37.1542L53.4486 34.7461C52.9165 34.5983 52.6049 34.0472 52.7526 33.515C52.9004 32.9829 53.4516 32.6713 53.9837 32.819L61.6921 34.9595L63.8325 27.2512C63.9803 26.719 64.5315 26.4074 65.0636 26.5552C65.5958 26.703 65.9074 27.2542 65.7596 27.7863L63.3516 36.4582ZM0.856366 0.932244C2.26153 0.7283 3.85626 0.595348 5.60494 0.557061L5.64872 2.55658C3.97311 2.59327 2.46019 2.72042 1.14363 2.91151L0.856366 0.932244ZM15.0541 1.19965C17.9646 1.64025 21.0627 2.32436 24.2634 3.31373L23.6728 5.22453C20.5711 4.26576 17.5706 3.60338 14.7548 3.17712L15.0541 1.19965ZM33.0588 6.75553C35.7911 8.06249 38.5415 9.61434 41.2623 11.4456L40.1455 13.1048C37.5087 11.33 34.8433 9.82619 32.1957 8.55974L33.0588 6.75553ZM48.6901 17.2645C50.9353 19.2976 53.1255 21.5577 55.231 24.0659L53.6991 25.3517C51.6526 22.9137 49.5258 20.7193 47.3477 18.747L48.6901 17.2645ZM60.8171 31.656C61.6531 32.9526 62.4677 34.2995 63.2586 35.6985L61.5175 36.6827C60.7455 35.3172 59.951 34.0034 59.1362 32.7397L60.8171 31.656Z" fill="#8BA2FF")

			.d-flex.flex-column.overflow-hidden.create__project-short.in-zoom(v-if="step != 0" :class="{'project-short-border': form.image}")
				.d-flex.justify-content-center.align-items-center.create__fake-image(v-if="!form.image" :class="{'glare': cover.img != null}")
					svg(xmlns="http://www.w3.org/2000/svg" width="66" height="67" viewBox="0 0 66 67" fill="none")
						path(opacity="0.2" d="M8.71718 47.1288C8.71704 46.9527 8.71704 46.7712 8.71704 46.5839V20.4175C8.71704 17.3645 8.71704 15.8368 9.31121 14.6707C9.83383 13.645 10.6672 12.8116 11.6929 12.289C12.859 11.6948 14.3867 11.6948 17.4397 11.6948H49.0575C52.1105 11.6948 53.6363 11.6948 54.8023 12.289C55.828 12.8116 56.6629 13.645 57.1854 14.6707C57.779 15.8357 57.779 17.3615 57.779 20.4085V46.5918C57.779 47.9138 57.779 48.949 57.7305 49.7926M8.71718 47.1288C8.71928 49.8225 8.75348 51.2328 9.31121 52.3274C9.83383 53.3531 10.6672 54.1893 11.6929 54.7118C12.8579 55.3055 14.3837 55.3055 17.4308 55.3055H49.0667C52.1137 55.3055 53.6374 55.3055 54.8023 54.7118C55.828 54.1893 56.6629 53.3531 57.1854 52.3274C57.5215 51.6678 57.6673 50.8935 57.7305 49.7926M8.71718 47.1288L21.713 31.967L21.7168 31.9629C22.8694 30.618 23.4467 29.9445 24.1314 29.7017C24.7329 29.4885 25.3896 29.4934 25.988 29.715C26.67 29.9677 27.2381 30.6488 28.3743 32.0122L35.6551 40.7491C36.7069 42.0113 37.2357 42.6459 37.8779 42.9007C38.4445 43.1253 39.0692 43.1507 39.6536 42.9781C40.319 42.7819 40.9047 42.1961 42.0759 41.0249L43.4306 39.6702C44.6231 38.4778 45.2194 37.8819 45.8943 37.6871C46.4869 37.5162 47.1206 37.553 47.6908 37.7882C48.3401 38.0564 48.8667 38.7127 49.9201 40.0295L57.7305 49.7926M57.7305 49.7926L57.779 49.8531M41.425 28.0488C39.9197 28.0488 38.6994 26.8285 38.6994 25.3232C38.6994 23.8178 39.9197 22.5975 41.425 22.5975C42.9304 22.5975 44.1507 23.8178 44.1507 25.3232C44.1507 26.8285 42.9304 28.0488 41.425 28.0488Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round")
				img.w-100(v-if="form.image" :src="form.image.contentUrl" :alt="form.title")
				.d-flex.flex-column.create__info
					.d-flex.create__fake-title.mb-2(v-if="!form.title")
					span.b1.mb-2.overflow-wrap(v-if="form.title") {{ form.title }}
					.d-flex.create__fake-text.mb-2(v-if="!form.description")
					.create__text-wrap(:class="{'expand-text': expand}")(v-if="form.description")
						p.b3.m-0.create__text
							span(ref="paymentText") {{ this.form.description }}
					.btn.btn-expand.mt-3(v-if="paymentTextHeight > 110 && form.description.length != 0 && form.description.length > 3 && form.description != null" @click="doExpand" :class="{'rotate': expand}") {{ !expand ? $t('forms.expand') : $t('forms.collapse') }}

		.d-flex.flex-column.create__right.position-relative
			.d-flex.d-lg-none.align-items-center.create__top-right-buttons(:class="{'create__top-right-buttons--top': step != 0}")
				locale-switch-simple
				span.create-bot__closing(:style="{backgroundColor: isDarkColor ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)'}" @click='openModalClose')
					svg(xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none")
						path(d="M4.36542 4.36572L12.1036 12.1039" :stroke="isDarkColor ? '#eaeaea' : '#082846'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
						path(d="M4.36542 12.104L12.1036 4.36579" :stroke="isDarkColor ? '#fff' : '#082846'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")

			.d-flex.flex-column.mx-auto.create__wrap.create__wrap-right
				.cursor-pointer.create__btn-back(:class="{'d-none': step == 0}" @click="step--, isMoveDownUp(), isloading = false")
					svg(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none")
						path(d="M9.57 5.92969L3.5 11.9997L9.57 18.0697" stroke="#52A7F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")
						path(d="M20.4999 12H3.66992" stroke="#52A7F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")

				.justify-content-between.position-relative.create__step-wrap.in-zoom(:class="{'d-none': step == 0, 'd-flex': step != 0}")
					svg(class="create__wrap-line" width="46" height="3" viewBox="0 0 46 3" fill="none" xmlns="http://www.w3.org/2000/svg")
						line(x1="-246" y1="1.4375" x2="240" y2="1.56246" :stroke="isDarkColor ? '#444444' : '#E4E7EA'" stroke-width="2" stroke-dasharray="10 15")
					h3.position-relative.create__step-inner(v-for="i in 4")
						svg(class="create__step-line" width="46" height="3" viewBox="0 0 46 3" fill="none" xmlns="http://www.w3.org/2000/svg")
							line(x1="0.00271646" y1="1.4375" x2="46.0025" y2="1.56246" :stroke="isDarkColor ? '#444444' : '#E4E7EA'" stroke-width="2" stroke-dasharray="9 9")
						div.create__step(:class="{'active pulsing': step == i, 'done': step > i}")
							span(v-if="step <= i") {{ i }}
							img(v-if="step > i" src="/assets/img/icons/done-green.svg")

				.create__inner(v-if="step == 0")
					h3.create__subtitle {{ $t('instruction.step0.title') }}

					.basic-box-shadow.border-rounded.foreground-color.d-flex.align-items-center.cursor-pointer.create__card(:class="{'active': card == 1}" @click="card = 1, scrollToCreateButton()")
						div
							h4.b1.mb-10 {{ $t('instruction.step0.monetization.title') }}
							p.text-grey.b3.mb-0.create__card-txt {{ $t('instruction.step0.monetization.txt') }}
						.position-relative.flex-shrink-0.create__image
							img.w-100.h-100(src="/assets/img/сreate/monetization.webp" alt="Monetization image" width="136" height="136")
							img.create__image-dollar(src="/assets/img/сreate/dollar.webp" alt="Dollar image" width="58" height="58")

					.basic-box-shadow.border-rounded.foreground-color.d-flex.align-items-center.cursor-pointer.create__card(:class="{'active': card == 2}" @click='card = 2, scrollToCreateButton()')
						div
							h4.b1.mb10 {{ $t('instruction.step0.neurobot.title') }}
							p.text-grey.b3.mb-0.create__card-txt {{ $t('instruction.step0.neurobot.txt') }}
							a.mt-1.d-inline-block.w-auto.create__card-link(href="https://docs.paywall.pw/rus/neirobot/chto-eto-takoe" target="_blank" @click.stop) {{ $t('instruction.step0.neurobot.moreDetails') }}
						.create__image.flex-shrink-0
							img.w-100.h-100(src="/assets/img/сreate/neurobot.webp" alt="Neurobot image" width="136" height="136")

					.btn.btn-primary.create__btn-next(id="create-button" :class="{'disabled': card == null || isloading}" @click="card == 1 ? step++ : createProjectNeuro()")
						.loader(v-if="isloading")
						span(v-else) {{ $t('wizard.next') }}

				div(v-if="step == 1")
					h3.mb-2 {{ $t('instruction.step3.title') }}
					p.b3.mb-5.text-grey(v-html="$t('instruction.step3.desc')")
					.btn.btn-primary.mb-5.create__btn(@click="step++, moveDownUp = true") {{ $t('wizard.next') }}

				div(v-if="step == 2")
					h3.mb-2 {{ $t('instruction.step4.title') }}
					p.b3.mb-5.text-grey(v-html="$t('instruction.step4.desc')")
					my-input.mb-5(
						v-model.trim="form.title"
						:label="$t('promotions.form.name')"
						:errorFeedback="errors.title"
						:icon="''"
						@input="isTitleValid(form.title)"
					)
					.btn.btn-primary.mb-5.create__btn(:class="{'disabled': errors.title != '' || form.title.length == 0 || form.title == null}" @click="step++, moveDownUp = false") {{ $t('wizard.next') }}

				div(v-if="step == 3")
					h3.mb-2 {{ $t('instruction.step5.title') }}
					p.b3.mb-5.text-grey(v-html="$t('instruction.step5.desc')")
					my-text-area.mb-5(
						v-model.trim="form.description"
						:label="$t('forms.placeholder.description')"
						:errorFeedback="errors.description"
						@input="isDescValid(form.description), resizeHandler()"
					)
					.btn.btn-primary.mb-5.create__btn(:class="{'disabled': errors.description != '' || form.description.length == 0 || form.description == null || isloading}" @click="createProject(), moveDownUp = true")
						.loader(v-if="isloading")
						span(v-else) {{ $t('wizard.next') }}

				div(v-if="step == 4")
					h3.mb-2 {{ $t('instruction.step6.title') }}
					p.b3.mb-3.text-grey(v-html="$t('instruction.step6.desc')")
					.d-flex.flex-wrap.mb-2.create__covers(v-if='defaultCovers.length > 0')
						img.mb-2.create__cover(
							v-for="(item, i) in randomCovers"
							:key="`cover-${item}`"
							:src="defaultCovers[item].contentUrl"
							:alt="`cover-${item}`"
							:class="{'active': form.image == defaultCovers[item]}"
							@click="selectImage(defaultCovers[item])"
						)

						.position-relative.cursor-pointer.create__cover.create__dropzone-image(v-if="cover.contentUrl")
							img.w-100(:src="cover.contentUrl")
							i.fontello-btn.fontello-btn-primary.fontello-icon-edit.create__dropzone-icon.create__dropzone-icon--edit(@click="editImage()")
							i.fontello-btn.fontello-btn-red.fontello-icon-trash.create__dropzone-icon.create__dropzone-icon--trash(@click="removeImage()")

						//- Drop zone for upload
						//- :class='{"is-invalid" : form.contentUrl == null && cover.img == null}'
						vue-dropzone.justify-content-center.align-items-center.create__cover.create__cover--dropzone(
							:key="udateDropzone"
							id="createDropzone"
							v-show="(!form.image && cover.img == null || !cover.contentUrl) && !coverError"
							:options='dropzoneOptions'
							ref="dropzoneInstance"
							v-on:vdropzone-success="addImage"
							v-on:vdropzone-file-added="selectedCover"
							v-on:vdropzone-error="'error'"
						)

						.d-flex.justify-content-center.align-items-center.create__cover.create__cover--error(v-if="coverError")
							img.cover-icon-error(src="/assets/img/current/cover-error.svg")

						//-  Cropper ZONE after upload image
						modal-general(:id="'cropper-zone'" :title="$t('project.cropEdit')" :contentClass="'cropper-zone'" :no-close-on-backdrop='true' @click="coverError = false")
							p.b1.mb-4.text-grey(v-if='cover.img != null') {{ $t('project.cropDesc') }}

							cropper.cropper(
								v-if='cover.img != null'
								:id='`cropper`'
								ref="cropper"
								:src="cover.img"
								:stencilProps='{aspectRatio: 16/9}')

							.d-flex.align-items-center.my-4(v-if="isMobile" @click="$refs.dropzoneInstance.$el.click()")
								<svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path stroke="#52A7F9" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9 17v-6l-2 2M9 11l2 2"/><path stroke="#52A7F9" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M22 10v5c0 5-2 7-7 7H9c-5 0-7-2-7-7V9c0-5 2-7 7-7h5"/><path stroke="#52A7F9" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M18 10c-3 0-4-1-4-4V2l8 8h-4Z"/></svg>
								span.b3.text-primary {{ $t('wizard.uploadPhoto') }}

							.d-flex.flex-column.flex-md-row.justify-content-end.mt-md-4(v-if='cover.img != null')
								.btn.btn-primary.mb-3.mb-md-0.mr-md-3.create__dropzone-button(@click="uploadCover()" :class="{'disabled': isPending || isloading}")
									.loader(v-if="isloading")
									span(v-else) {{$t('forms.install')}}
								.btn.btn-border.mr-md-auto.create__dropzone-button(@click="cancelCroppingCover()") {{$t('forms.cancel2')}}

					span.d-flex.b3.text-primary.mb-4.cursor-pointer.create__more(@click="openModal") {{ $t('button.moreCovers') }}
					.btn.btn-primary.mb-5(:class="{'disabled': form.image == null || isPending}" :disabled='isPending' @click="goToProject")
						.loader(v-if="isPending")
						span(v-else) {{ $t('forms.done') }} 😍

		b-modal(id="modal-create-stop" ref='modal-create-stop' centered content-class="modal-stop" header-class="d-flex p-0 border-0 align-items-center mb-4" body-class="p-0 d-flex flex-column modal-xs" size='xs' hide-footer hide-header-close)
			template(#modal-header="{ close }")
				span.b1 {{ $t("project.stop.title") }}
				icon-close.modal-promotions__close(@click="close()")
			span.b3.mb-3(v-html='$t("project.stop.desc")')
			.d-flex.justify-content-between
				.btn.btn-border.btn-small.w-100.mr-4(@click="close") {{ $t('promotions.cancel') }}
				.btn.btn-small.btn-primary.w-100(@click='goBack')
					.loader(v-if='isloading')
					span(v-else) {{ $t('project.stop.btnClose') }}

		modal-general(:id="'covers'" :contentClass="'content-covers'" :title="$t('wizard.chooseCover')" @onHide='changeCategory(false)')
			dropdown-general.mb-3.mr-md-auto(:list="dropdownCategoryList" :size="'small'" @onChange='changeCategory')
			carousel.mb-3.mb-md-0(ref="myCarousel" :per-page="1" :navigationEnabled="isMobile ? false : true" :key="myCarouselKey" :navigation-prev-label="prevLabel" :navigation-next-label="nextLabel")
				slide(v-for="(item, i) in slides" :key="`slide-${i}`")
					img.create__cover.create__cover--modal(
						v-for="(img, j) in item"
						:key="`cover-modal-${j}`"
						:src="img.contentUrl"
						:alt="`cover-modal-${j}`"
						:class="{'active': mоdalCover == img}"
						@click="mоdalCover = img"
					)

			.d-flex.flex-column.flex-md-row.mt-auto.mt-md-4.mb-3.mb-md-0
				.btn.btn-primary.mr-md-3.mb-3.mb-md-0.create__button(@click="selectImage(mоdalCover), $bvModal.hide('covers')" :class="{'disabled': mоdalCover == null}") {{$t('forms.install')}}
				.btn.btn-border.mr-md-auto.create__button(@click="$bvModal.hide('covers'), mоdalCover = null") {{$t('forms.cancel2')}}
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import vue2Dropzone from 'vue2-dropzone';
import { Cropper } from 'vue-advanced-cropper';
import { STORAGE_LAST_OPENED_ITEM } from '@/api/storage';
import { DOMAIN } from '@/api/index'
import { IMAGES_CATEGORIES } from '@/models/image';
import { DROPZONE_OPTIONS } from '@/constants/config';
import { mapActions, mapGetters } from 'vuex';
import MyInput from "@/components/UI/MyInput";
import MyTextArea from "@/components/UI/MyTextArea";
import ModalGeneral from '@/components/Modals/ModalGeneral';
import IconClose from "@/components/Common/IconClose";
import DropdownGeneral from '@/components/Dropdown/DropdownGeneral';
import heic2any from 'heic2any';
import LocaleSwitchSimple from '@/components/Common/LocaleSwitchSimple';
import CreateLeftBasic from "@/components/Common/CreateLeftBasic";

var dataURLtoBlob = require('blueimp-canvas-to-blob')

export default {
	name: 'Create',
	components: {
		vueDropzone: vue2Dropzone,
		Cropper,
		MyInput,
		MyTextArea,
		ModalGeneral,
		Carousel,
		Slide,
		IconClose,
		DropdownGeneral,
		LocaleSwitchSimple,
		CreateLeftBasic
	},
	metaInfo() { return { title: this.$t('project.add') } },
	data: () => ({
		card: null,
		coverError: false,
		isloading: false,
		step: 4,
		isDisabled: true,
		expand: false,
		paymentTextHeight: null,
		moveDownUp: false,
		key: 0,
		form: {
			title: "",
			description: "",
			image: null,
			id: null
		},
		errors: {
			title: "",
			description: "",
		},
		mоdalCover: null,
		myCarouselKey: 0,
		nextLabel: `<svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" fill="none"><path stroke="#52A7F9" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m1.215 11.169 4.987-5.001-4.987-5.001"/></svg>`,
		prevLabel: `<svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" fill="none"><path stroke="#52A7F9" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M5.785 1.167.798 6.168l4.987 5"/></svg>`,
		udateDropzone: 0,
		cover: {
			img: null,
			contentUrl: null
		},
		defaultCovers: [],
		selectedCategory: _.map(IMAGES_CATEGORIES, i => i.id),
		removeBtnPressed: false
	}),
	destroyed() {
		document.body.classList.remove("background-login");
	},
	computed: {
		...mapGetters({
			projectList: 'project/itemsShort',
			item: 'project/opened',
			isPending: 'project/isPending',
			lastOpenedProjectId: 'project/lastOpenedProjectId'
		}),

		filteredDefaultCovers() {
			if (_.isArray(this.selectedCategory)) return this.defaultCovers;

			return _.filter(this.defaultCovers, d => {
				return d.category == this.selectedCategory
			});
		},

		slides() {
			let size = this.isMobile ? 18 : 12;
			let tabs = Math.ceil(this.filteredDefaultCovers.length / size);
			let arr = [];

			if (this.filteredDefaultCovers.length <= size) return [this.filteredDefaultCovers];
			for (let i = 0; i < tabs; i++) {
				arr[i] = this.filteredDefaultCovers.slice((i * size), (i * size) + size);
			}

			return arr;
		},

		dropzoneOptions() {
			return DROPZONE_OPTIONS.apply(this);
		},

		randomCovers() {
			let arr = [];

			for (let i = 0; i < 20; i++) {
				arr.push(this.getRandomInt(0, this.defaultCovers.length));
			}

			return _.uniq(arr).slice(0, 7);
		}
	},
	mounted() {
		this.resizeHandler();
		window.addEventListener('resize', this.resizeHandler);
		this.step = this.$route.params.step || 0;
		this.$root.$on('bv::modal::hide', () => {
			this.udateDropzone++;
		})
		document.body.classList.add("background-login");
	},
	async created() {
		this.$store.commit('project/setOpened', null);
		this.getCategoryList();

		if (!this.projectList || this.projectList.length == 0) {
			await this.getProjects();
		}

		this.getDefaultCovers({ category: this.selectedCategory, itemsPerPage: 1000 }).then(v => this.defaultCovers = v);
		if (this.$route.query && this.$route.query.id) {
			if (!this.item || this.item == null) await this.getProject({ id: this.$route.query.id });
			this.step = 4;
			this.setForm();
		}
	},
	methods: {
		...mapActions({
			add: 'project/add',
			addNeuro: 'project/addNeuro',
			edit: 'project/edit',
			getProject: 'project/id',
			getProjects: 'project/shortList',
			getDefaultCovers: 'project/covers',
			addImage: 'users/putImage',
			removeProject: 'project/remove',
		}),
		scrollToCreateButton() {
			let tariffs = document.querySelector('#create-button');
			let elementTop = tariffs.offsetTop ? tariffs.offsetTop : 0;

			scrollTo(0, elementTop);
		},
		goToAddBot() {
			/* this.$emit('isShow', false);
			this.$router.push({ name: 'add_bot', query: { from: 'create' } }); */
		},
		getRandomInt(min, max) {
			min = Math.ceil(min);
			max = Math.floor(max);
			return Math.floor(Math.random() * (max - min + 1)) + min;
		},
		openModalClose() {
			this.$bvModal.show('modal-create-stop')
		},
		close() {
			this.$bvModal.hide('modal-create-stop')
		},
		isMoveDownUp() {
			this.key++

			if (this.step == 2 || this.step == 4) {
				this.moveDownUp = true;
			} else if (this.step == 1 || this.step == 3) {
				this.moveDownUp = false;
			}
		},
		setLastOpenedItem() {
			if (this.lastOpenedProjectId) {
				let pr = _.find(this.projectList, p => p.id == this.lastOpenedProjectId);
				if (pr) {
					this.$store.commit('project/setOpened', pr);
					this.$router.push({ name: 'project_dashboard', params: { id: this.lastOpenedProjectId } });
					return true;
				}
			}

			return false;
		},
		async goBack() {
			if (this.form.id) {
				this.isloading = true;
				await this.removeProject({ id: this.form.id });
				this.isloading = false;
			};

			if (this.setLastOpenedItem()) { return }

			return this.projectList && this.projectList.length > 0 ? this.$router.push({ name: 'profile' }) : this.$router.push({ name: 'logout' });
		},
		setForm() {
			this.form.title = this.item.title;
			this.form.description = this.item.description;
			this.form.image = this.item.image;
			this.form.contentUrl = this.item.image != null && this.item.image.contentUrl ? this.item.image.contentUrl : null;
			this.form.id = this.item.id;
		},
		async createProject() {
			this.isloading = true;
			let project = {
				title: this.form.title,
				description: this.form.description,
			}

			if (this.form.id) {
				project.id = this.form.id;
				this.edit(project).then((i) => {
					this.resizeHandler();
					this.step++;
					this.isloading = false;
				})
					.catch((v) => {
						this.$notify("error", v);
						this.isloading = false;
					});
				return;
			}

			this.add(project).then(i => {
				this.form.id = i.id;
				this.step++;
				this.$router.push({ name: 'add_project', query: { id: i.id } })
				this.isloading = false;
			})
				.catch(v => {
					this.$notify("error", v);
					this.isloading = false;
				})
		},
		async createProjectNeuro() {
			this.isloading = true;

			this.addNeuro({}).then(i => {
				this.$cookies.set(STORAGE_LAST_OPENED_ITEM, i.id, '1M', null, DOMAIN, null, 'Lax');
				this.$store.commit('project/setFromCreatePage', true);
				this.$store.commit('project/setLastOpenedProjectId', i.id);
				this.$store.commit('project/setOpened', i);
				this.$router.push({ name: 'project_dashboard', params: { id: i.id } });
				this.isloading = false;
			})
				.catch(v => {
					this.$notify("error", v);
					this.isloading = false;
				})
		},
		openModal() {
			this.$bvModal.show('covers');

			setTimeout(() => {
				this.$refs.myCarousel.$forceUpdate();
				this.myCarouselKey++;
				setTimeout(() => {
					window.dispatchEvent(new Event('resize'));
				}, 100);
			}, 99);
		},
		doExpand() {
			this.expand = !this.expand;
		},
		resizeHandler() {
			setTimeout(() => {
				if (this.$refs.paymentText) {
					this.paymentTextHeight = this.$refs.paymentText.offsetHeight;
				};
			}, 100);
		},
		isTitleValid(v) {
			this.errors.title = '';

			if (v == null || v.length == 0)
				return this.errors.title = this.$t('promotions.form.error');

			if (v.length < 3)
				return this.errors.title = this.$t('errors.minLength.title');

			if (v.length > 50)
				return this.errors.title = this.$t('errors.maxLength.title');

			if (_.find(this.projectList, p => p.title == v))
				return this.errors.title = this.$t('errors.projectExist');
		},
		isDescValid(v) {
			this.errors.description = '';

			if (v == null || v.length == 0) {
				this.errors.description = this.$t('errors.required.description');
			} else if (v.length < 3) {
				this.errors.description = this.$t('errors.minLength.description');
			} else if (v.length > 5000) {
				this.errors.description = this.$t('errors.maxLength.description');
			}
		},
		selectImage(item) {
			this.cover.contentUrl = null;
			this.form.image = item;
			this.cover.contentUrl = null;
			this.edit({ id: this.form.id, image: item.id });
		},
		editImage() {
			this.$bvModal.show('cropper-zone');
			this.cover.img = this.cover.contentUrl;
		},
		removeImage() {
			this.$store.dispatch("project/removeImage", { id: this.form.image.id });
			this.form.contentUrl = null;
			this.form.image = null;
			this.cover.contentUrl = null;
		},
		addImage(file, response) {
			this.form.image = response;
		},
		async selectedCover(file) {
			let img = null;
			let type = file.type;

			/* Перевёл в МБ */
			if ((file.size / 1024) / 1024 > 5) {
				this.$notify("error", this.$t('errors.imgBigError'));
				this.isloading = false;
				this.udateDropzone++;
				return false;
			}

			if (file && (file.type.match('heic') || file.name.match('heic'))) {
				img = await heic2any({ blob: file, toType: "image/jpeg", quality: 0.2 });
				type = "image/jpeg";
			}

			var reader = new FileReader()

			reader.onload = (e) => {
				let data;

				if (typeof e.target.result === 'object') {
					data = window.URL.createObjectURL(img == null ? new Blob([e.target.result]) : img)
					this.$bvModal.show('cropper-zone');
				} else {
					data = e.target.result
				}
				this.cover.img = data
			}

			this.fileType = type;
			this.fileExt = _.last(type.split('/'));

			reader.readAsArrayBuffer(file)
		},
		cancelCroppingCover() {
			this.cover.img = null;
			this.coverError = false;
			this.$bvModal.hide('cropper-zone');
		},
		getFixedUploadCover() {
			let isBigger = false;
			var img = this.$refs.cropper.getResult();
			let imgFixed;

			if (img.coordinates.width > img.canvas.width) {
				img.coordinates.width = img.canvas.width;
				isBigger = true;
			}

			if (img.coordinates.height > img.canvas.height) {
				img.coordinates.height = img.canvas.height;
				isBigger = true;
			}

			if (isBigger) {
				this.$refs.cropper.setCoordinates(img.coordinates);
				imgFixed = this.$refs.cropper.getResult();
			}

			return img.canvas.toDataURL(this.fileType);
		},
		uploadCover() {
			if (this.isPending) return false;
			this.isloading = true;

			this.$store.dispatch("users/putImage", { data: { file: new File([dataURLtoBlob(this.getFixedUploadCover())], "cover." + this.fileExt) } })
				.then(i => {
					this.form.image = i;
					this.form.contentUrl = i.contentUrl;
					this.cover.contentUrl = this.getFixedUploadCover();
					this.cover.img = null;

					this.$bvModal.hide('cropper-zone');
					this.edit({ id: this.form.id, image: i.id });
					this.isloading = false;
				})
				.catch(v => {
					let text = v;
					this.coverError = true;

					if (v == 'ERR_NETWORK') text = this.$t('errors.imageTooBig');
					if (v != 'ERR_NETWORK') this.coverError = false;

					this.$notify("error", text);
					this.isloading = false;
				});
		},

		goToProject() {
			this.isloading = true;
			this.$cookies.set(STORAGE_LAST_OPENED_ITEM, this.item.id, '1M', null, DOMAIN, null, 'Lax');
			this.$store.commit('project/setFromCreatePage', true);
			this.$store.commit('project/setLastOpenedProjectId', this.item.id);
			this.$store.commit('project/setOpened', this.item);
			this.$router.push({ name: 'project_dashboard', params: { id: this.form.id } });
		},
		changeCategory(i) {
			this.selectedCategory = i ? i : _.map(IMAGES_CATEGORIES, i => i.id);
		},
		getCategoryList() {
			this.dropdownCategoryList = [{ id: this.selectedCategory, title: this.$t('forms.all') }, ..._.map(IMAGES_CATEGORIES, i => {
				return { id: i.id, title: this.$t(i.title) };
			})];
		}
	}
}
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.create {
	position: relative;
	min-height: 100vh;
	overflow: hidden;

	@include respond-below(md) {
		flex-direction: column;

		&.revers {
			flex-direction: column-reverse !important;

			.create__wrap {
				margin: 20px auto 12px !important;
			}

			.create__left {
				border-radius: 50px 50px 0 0;
				background: linear-gradient(90deg, #5EAEFE 0%, #0532D0 100%);
				box-shadow: -20px 0px 100px 0px rgba(68, 116, 238, 0.20);
			}
		}
	}

	&__your-project {
		position: absolute;
		top: 200px;
		left: 0;
		padding: 20px 32px;
		font-size: 24px;
		line-height: 28px;
		color: #fff;
		border-radius: 0px 100px 100px 0px;
		background: rgba(255, 255, 255, 0.05);

		@include respond-below(md) {
			display: none;
		}
	}

	&__decor-image {
		position: absolute;
		top: 192px;
		left: 248px;
		width: 84px;
		transform: rotate(8.577deg);

		&--2 {
			top: 182px;
			left: 240px;
			width: 112px;
		}

		&--3 {
			top: 188px;
			left: 238px;
			width: 104px;
			transform: rotate(-8.577deg);
		}

		@include respond-below(md) {
			display: none;
		}
	}

	&__dashed-arrow {
		position: absolute;
		top: 236px;
		left: 380px;

		@include respond-below(md) {
			display: none;
		}
	}

	&__decor-wrap {
		display: none;

		@include respond-below(md) {
			position: absolute;
			top: 22px;
			left: 50%;
			display: flex;
			width: 336px;
			transform: translateX(-50%);

			.create__your-project {
				display: block;
				top: 0;
				left: 0;
				padding: 12px 30px;
				font-size: 14px;
				line-height: 22px;
				border-radius: 100px;
			}

			.create__decor-image {
				display: block !important;
				top: 2px;
				left: 50%;
				width: 70px;
				transform: rotate(8.577deg);

				&--2 {
					top: -6px;
					left: 48%;
					width: 86px;
				}

				&--3 {
					top: -2px;
					left: 48%;
					width: 84px;
					transform: rotate(-8.577deg);
				}
			}

			.create__dashed-arrow {
				display: block;
				top: 38px;
				left: auto;
				right: 8px;
			}
		}
	}

	// Start Login as author
	&__author {
		padding: 24px 0;
		max-width: 506px;
	}

	&__author-qr {
		width: 230px;
		height: 230px;
		margin-bottom: 36px;

		@include respond-below(md) {
			width: 167px;
			height: 167px;
			margin-bottom: 25px;
		}
	}

	&__author-title {
		font-size: 24px;
		line-height: calc(28/24);
		margin-bottom: 20px;

		@include respond-below(md) {
			font-size: 18px;
			line-height: calc(28/18);
			margin-bottom: 5px;
		}
	}

	&__author-text {
		font-size: 18px;
		line-height: 30.5px;
		margin-bottom: 36px;

		@include respond-below(md) {
			max-width: 331px;
			font-size: 14px;
			line-height: 22px;
			margin-bottom: 25px;
		}
	}

	&__author-link {
		width: 408px;
		margin-bottom: 35px;

		@include respond-below(md) {
			margin-bottom: 26px;
		}

		@include respond-below(sm) {
			width: 100%;
		}
	}

	&__author-instruction {
		padding: 10px 20px;
		width: 252px;
		box-shadow: var(--card-box-shadow);

		span {
			color: var(--main-text-color) !important;
		}

		@include respond-below(md) {
			width: 226px;
		}
	}

	&__author-subtext {
		font-size: 14px;
		line-height: calc(22/14);

		@include respond-below(md) {
			font-size: 12px;
			line-height: calc(18/12);
		}
	}

	&__author-decor {
		position: absolute;
		top: -18px;
		left: -70px;

		@include respond-below(md) {
			top: -2px;
			left: -46px;
		}
	}

	// End Login as author

	&__step-wrap {
		margin-bottom: 40px !important;

		@include respond-below(md) {
			margin-bottom: 20px !important;
		}
	}

	&__wrap-right {
		max-width: 469px;
	}

	&__wrap-line {
		display: none;

		@include respond-below(xs) {
			position: absolute;
			display: block;
			top: 50%;
			right: 0;
			left: 0;
			width: 100%;
		}
	}

	&__step-inner {
		position: relative;

		&:nth-child(2) {
			.create__step-line {
				display: none;
			}
		}
	}

	&__step-line {
		position: absolute;
		top: 50%;
		right: 160%;

		@include respond-below(xs) {
			display: none;
		}
	}

	&__step {
		position: relative;
		z-index: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 45px;
		height: 45px;
		color: var(--grayscale-dark-grey);
		background-color: var(--grayscale-bg-color-dark);
		border-radius: 50%;

		&.active {
			color: var(--grayscale-main-black);
			background: var(--brand-warning);
			box-shadow: var(--box-shadow-yellow);
		}

		&.done {
			background-color: var(--brand-bg-success);
		}
	}

	&__btn {
		height: 64px;

		@include respond-below(md) {
			height: 50px;
		}
	}

	&__more {
		width: max-content;
	}

	&__btn-back {
		width: max-content;
		padding: 9px 19px;
		background-color: var(--brand-bg-primary);
		border-radius: var(--border-radius-rounded);
		transition: background-color var(--animation-time-short);
		margin-bottom: 40px;

		svg {
			path {
				transition: stroke var(--animation-time-short);
			}
		}

		&:hover {
			svg {
				path {
					stroke: var(--primary-dark-text-color) !important;
				}
			}
		}

		&:active {
			background-color: var(--primary-dark-bg-color);

			svg {
				path {
					stroke: var(--primary-dark-text-color) !important;
				}
			}
		}

		@include respond-below(md) {
			padding: 5px 13px;
			margin-bottom: 20px;
		}
	}

	&__btn-next {
		margin-top: 30px;

		@include respond-below(md) {
			margin-top: 40px;
		}

		@include respond-below(sm) {
			height: 50px;
		}
	}

	&__project-short {
		width: 394px;
		border-radius: var(--border-radius-rounded);
		border: 1px solid rgba(255, 255, 255, 0.30);
		margin: 340px auto 96px;

		img {
			height: 221px;
			object-fit: cover;
		}

		@include respond-below(md) {
			width: 100%;
			max-width: 340px;
			margin: 126px auto 42px;
		}
	}

	&__fake-image {
		height: 222px;
		background-color: rgba(255, 255, 255, 0.05);

		&.glare {
			position: relative;
			overflow: hidden;

			&::before {
				content: '';
				width: 100%;
				height: 200%;
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0.5;
				background: linear-gradient(to left, transparent 0%, #ddd 40%, #ddd 60%, transparent 100%);
				transform: translate(-100%, -25%) rotate(10deg);
				animation: blick 4s infinite;
			}

			@keyframes blick {
				0% {
					transform: translate(-100%, -25%) rotate(10deg);
				}

				20% {
					transform: translate(100%, -25%) rotate(10deg);
				}

				100% {
					transform: translate(100%, -25%) rotate(10deg);
				}
			}
		}
	}

	&__info {
		padding: 20px;

		span,
		p {
			color: #fff;
		}
	}

	.project-short-border {
		border: none;

		.create__info {
			border: 1px solid rgba(255, 255, 255, 0.30);
			border-top: none;
			border-bottom-left-radius: var(--border-radius-rounded);
			border-bottom-right-radius: var(--border-radius-rounded);
		}
	}

	&__fake-title {
		height: 56px;
		background-color: rgba(255, 255, 255, 0.05);
	}


	&__fake-text {
		height: 128px;
		background-color: rgba(255, 255, 255, 0.05);
	}

	&__text-wrap {
		max-height: 110px;
		overflow: hidden;
		transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);

		.create__text {
			word-wrap: break-word;
			white-space: break-spaces;
		}

		&.expand-text {
			max-height: 1000rem;
			transition: max-height 0.3s cubic-bezier(0.9, 0, 0.8, 0.2);

			.create__text {
				animation: open 0.1s linear 0s forwards;
			}
		}
	}

	&__text {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		animation: close 0.1s linear 0.1s forwards;
	}

	.btn-expand {
		padding: 0 37px 0 0;
		background-color: transparent;
		color: var(--brand-warning);

		&::before {
			top: 0;
			background-color: var(--brand-warning);
		}

		&:hover,
		&:active {
			background-color: transparent;
			color: var(--brand-warning);

			&::before {
				background-color: var(--brand-warning);
			}
		}
	}

	@keyframes open {
		from {
			-webkit-line-clamp: 5;
		}

		to {
			-webkit-line-clamp: initial;
		}
	}

	@keyframes close {
		from {
			-webkit-line-clamp: initial;
		}

		to {
			-webkit-line-clamp: 5;
		}
	}

	&__cover {
		width: calc(25% - 7.5px);
		height: 60px;
		border-radius: var(--border-radius-rounded);
		transition: box-shadow var(--animation-time-short);
		margin: 0 10px 10px 0;
		cursor: pointer;

		&:hover,
		&:active {
			box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.40);
			//box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.70)
		}

		&.active {
			border: 3px solid var(--brand-primary);

			&:hover,
			&:active {
				box-shadow: none;
			}
		}

		&:nth-child(4n+4) {
			margin-right: 0;

			@include respond-below(sm) {
				margin-right: 10px;
			}
		}

		&:nth-child(3n+3) {
			@include respond-below(sm) {
				margin-right: 0;
			}
		}

		@include respond-below(sm) {
			width: calc(33.33% - 7.5px);
			height: auto;
		}

		&--modal {
			height: 125px;
			object-fit: cover;

			@include respond-below(md) {
				height: 90px;
			}

			@include respond-below(xs) {
				height: 58px;
			}

			&:hover,
			&:active {
				box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.20);
			}
		}

		&--error {
			border: 2px dashed var(--brand-error);
		}

		.text-error {
			display: none !important;
		}

		&--dropzone {
			display: flex;
		}
	}

	&__button {
		width: 185px;

		@include respond-below(sm) {
			width: 100%;
		}
	}

	&__dropzone-image {
		border-radius: var(--border-radius-rounded);
		border: 3px solid var(--brand-primary);
		overflow: hidden;

		@include respond-below(sm) {
			margin-bottom: 6px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			background-color: transparent;
			transition: var(--animation-time-short);
		}

		&:hover {
			&::before {
				background-color: rgba(#000, 0.6);
			}

			.create__dropzone-icon {
				visibility: visible;
			}
		}
	}

	.dropzone {
		min-height: auto;
		transition: var(--animation-time-short);

		@media(max-width: 768px) {
			.dz-message {
				margin: 0 !important;
				transform: translateY(-70%);
			}

			padding: 2px;
			margin-bottom: 7px;
		}

		@media(max-width: 470px) {
			.dz-message {
				transform: translateY(-60%);
			}
		}

		@media(max-width: 440px) {
			.dz-message {
				transform: translateY(-50%);
			}
		}

		@media(max-width: 380px) {
			.dz-message {
				transform: translateY(-47%);
			}
		}

		@media(max-width: 320px) {
			.dz-message {
				img {
					width: 20px !important;
					height: 20px !important;
				}

				.b3 {
					font-size: 11px !important;
				}
			}
		}
	}

	.cover-icon,
	.cover-icon-hover {
		width: 28px !important;
		height: 28px !important;
		margin-bottom: 0 !important;
	}

	.cover-icon-error {
		width: 28px !important;
		height: 28px !important;
		margin-bottom: 0 !important;
	}

	&__dropzone-icon {
		visibility: hidden;
		position: absolute;
		padding: 10px;
		top: 50%;
		transform: translateY(-50%);
		transition: 100ms;

		@include respond-below(sm) {
			width: 39px;
			height: 39px;
			font-size: 18px;
		}

		&--edit {
			left: 9px;

			@include respond-below(sm) {
				left: 8px;
			}
		}

		&--trash {
			right: 9px;

			@include respond-below(sm) {
				right: 8px;
			}
		}
	}

	&__dropzone-button {
		width: 185px;

		@include respond-below(sm) {
			width: 100%;
			min-height: 50px;
			height: 50px;
		}
	}

	&__success {
		width: 547px;
		padding: 40px;

		@include respond-below(sm) {
			overflow: hidden;
			width: 100%;
			padding: 15px 20px 30px;

			h3 {
				z-index: 1;
			}

			.create__close {
				z-index: 2;
			}

			.btn {
				z-index: 2;
			}
		}
	}

	&__confetti {
		position: absolute;
		top: -170px;
		right: -220px;
		width: 250px;

		@include respond-below(sm) {
			top: auto;
			bottom: 50px;
			right: -50px;
			width: 150px;
			transform: rotate(-90deg);
		}
	}

	&__subtitle {
		margin-bottom: 30px !important;
	}

	&__image {
		margin-left: 40px;
		width: 136px;
		height: 136px;

		@include respond-below(xs){
			width: 95px;
			height: 95px;
			margin-left: 27px;
		}

		&-dollar {
			position: absolute;
			top: 4px;
			left: -14px;

			@include respond-below(xs){
				width: 44px;
				height: 44px;
				top: 2px;
				left: -12px;
			}
		}
	}

	&__card {
		border: 1px solid transparent;
		padding: 19px;
		margin-bottom: 16px;

		&:hover {
			background: linear-gradient(var(--foreground-color) 0 0) padding-box, linear-gradient(260.48deg, #0071F4 5.07%, #66B4FF 98.17%) border-box;
			border-radius: 8px;

			@include respond-below(sm){
				background: none;
			}
		}

		&.active {
			background: linear-gradient(var(--foreground-color) 0 0) padding-box, linear-gradient(260.48deg, #0071F4 5.07%, #66B4FF 98.17%) border-box;
			border-radius: 8px;
		}

		&:last-of-type {
			margin-bottom: 0;
		}

		&-txt {
			@include respond-below(xs) {
				font-size: 12px !important;
				line-height: calc(18/12) !important;
			}
		}

		&-link {
			@include respond-below(xs) {
				font-size: 12px !important;
				line-height: calc(18/12) !important;
				margin-top: 0 !important;
			}
		}
	}

	&__inner {
		margin-top: 30px;
		margin-bottom: 70px;
	}
}
</style>
